<template>
  <div v-if="pageLoading">
    <img style="width: 30%" class="d-block mx-auto" src="@/assets/loader.gif" />
  </div>
  <div
    v-else-if="singleBusiness.tranrsactionreference == null"
    class="card my-5 py-5 w-50 d-block mx-auto border"
  >
    <img
      class="d-block mx-auto"
      style="width: 120px"
      src="@/assets/credit-card.png"
      alt=""
    />
    <div class="card-body">
      <h3 class="card-title text-center mb-5">Make Payment</h3>
      <div class="row">
        <div class="col-md-6">
          <p><b>Payment in Favour Of:</b> {{ singleBusiness.insuredName }}</p>
          <p>
            <b>Sum Insured:</b>
            {{
              singleBusiness.sumInsured != null
                ? parseInt(singleBusiness.sumInsured).toLocaleString("en-US")
                : ""
            }}
            Naira Only
          </p>
          <p>
            <b>Premium:</b>
            {{
              singleBusiness.premium != null
                ? parseInt(singleBusiness.premium).toLocaleString("en-US")
                : ""
            }}
            Naira Only
          </p>
        </div>

        <div class="col-md-4 offset-md-2">
          <p><b>CHI NO:</b> {{ singleBusiness.certifateNo }}</p>
          <p>
            <b>Effective Date:</b>
            {{
              singleBusiness.startDate != null
                ? singleBusiness.startDate.split("T")[0]
                : ""
            }}
          </p>
          <p><b>Rate:</b> {{ singleBusiness.premiumRate }}%</p>
        </div>
      </div>
      <button
        @click.prevent="cancel()"
        class="btn btn-warning text-center mt-5"
      >
        Cancel
      </button>
      <button
        @click.prevent="pay()"
        class="btn btn-success text-center mt-5 float-right"
      >
        Pay Now
      </button>
    </div>
  </div>

  <div v-else class="card my-5 py-5 w-50 d-block mx-auto border">
    <img
      class="d-block mx-auto"
      style="width: 200px"
      src="@/assets/paid.png"
      alt=""
    />

    <button
      @click.prevent="
        $router.push(`/agent/viewBusiness/${singleBusiness.certifateNo}`)
      "
      class="btn btn-primary text-center d-block mx-auto mt-5"
    >
      View Details
    </button>
  </div>
</template>

<script>
//import AppLayout from "@/layouts/AppLayout.vue";
import Swal from "sweetalert2";
import { $toWords } from "@/helpers";
import { mapState, mapActions } from "pinia";
import { useMarineStore } from "@/stores/marine";
import { useMainStore } from "@/stores/main";
import $config from "../../../../helpers/config";
// import axios from "axios";
export default {
  name: "Buy Marine",
  //components: { AppLayout },
  data() {
    return {
      tranrsactionreference: "",
      pluralCurrencies: ["DOLLAR", "EURO", "POUND"],
    };
  },
  computed: {
    insuredWords() {
      return $toWords.convert(
        parseInt(
          this.singleBusiness.sumInsured != null
            ? this.singleBusiness.sumInsured
            : "0"
        )
      );
    },
    ...mapState(useMarineStore, ["loading", "pageLoading", "singleBusiness"]),
    ...mapState(useMainStore, ["user"]),
  },

  methods: {
    ...mapActions(useMarineStore, [
      "getBookedBusinesses",
      "getBusiness",
      "getSingleBusiness",
      "makePayment",
      "incrementStep",
    ]),

    async flwPay() {
      //eslint-disable-next-line no-undef
      FlutterwaveCheckout({
        public_key: $config.flwLivePublicKey,
        tx_ref: this.tranrsactionreference,
        amount: this.singleBusiness.premium,
        currency: "NGN",
        payment_options: "card, banktransfer, ussd",
        callback: () => {
          this.makePayment({
            certificateNo: [this.singleBusiness.certifateNo],
            startDate: this.singleBusiness.startDate,
            policyno: this.singleBusiness.policyno,
            coverScope: this.singleBusiness.coverScope,
            sumInsured: this.singleBusiness.sumInsured,
            premium: this.singleBusiness.premium,
            premiumRate: this.singleBusiness.premiumRate,
            endorsement: this.singleBusiness.endorsement,
            submittedBy: "Marine Portal",
            transactionReference: this.tranrsactionreference,
          }).then(() => {
            this.incrementStep(1);
            window.location = `/agent/allBookedBusinesses`;
          });
        },
        meta: {
          broker_id: this.user.brokerId,
        },
        customer: {
          email: this.user.email,
          name: this.user.broker,
        },
        customizations: {
          title: "Consolidated Hallmark Insurance",
          description: "Payment for marine insurance",
          logo: "https://s3-symbol-logo.tradingview.com/consolidated-hallmark-insurance-plc--600.png",
        },
      });
    },

    async pay() {
      this.tranrsactionreference = `CHIMARINETXR-${Math.floor(
        Math.random() * 100000000
      )}`;

      this.flwPay();
    },

    cancel() {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: true,
        cancelButtonText: "No, Go Back",
        confirmButtonText: "Yes Cancel",
        text: "Are You Sure You Want To Cancel Payment",
        reverseButtons: true,
        confirmButtonColor: "#990100",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push(`/agent/allBookedBusinesses`);
        }
        //   else if (result.dismiss === Swal.DismissReason.cancel) {
        //     this.$router.push(`/agent/viewBusiness/${res.certificateNo}`);
        //   }
      });
    },
  },

  created() {
    this.incrementStep(4);
    // if (this.steps < 3) {
    //   this.$router.push(`/agent/buymarine/step-${this.steps}`);
    // }
    this.getSingleBusiness(this.$route.params.id);
  },

  beforeUnmount() {
    this.incrementStep(1);
  },
};
</script>